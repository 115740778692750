import React from "react"
import { colors } from "../../styles/globals"
import { cereriStyles } from "./styles"

const styles = {
  link: {
    color: colors.main,
    fontWeight: "bold",
    textDecoration: "none",
  },
}

export default function CustomCheckInput({
  id,
  checked,
  onChange,
  showError,
  labelText,
  isPolicyCheck,
  labelClassName,
}) {
  return [
    <input
      key={1}
      type="checkbox"
      id={id}
      name={id}
      style={{ float: "left", marginTop: 4 }}
      checked={checked}
      onChange={onChange}
    />,
    <label
      key={2}
      style={{ color: showError ? colors.red : colors.main }}
      className={"cereriCheckInputLabel " + labelClassName}
      htmlFor={id}
      // onClick={onChange}
    >
      {isPolicyCheck ? (
        <span>
          Sunt de accord cu{" "}
          <a
            style={{
              ...styles.link,
              color: showError ? colors.red : colors.main,
            }}
            target="_blank"
            href="/documente/Terms&Conditions.pdf"
          >
            <span>termeni și condițiile de utilizare</span>
          </a>{" "}
          și cu{" "}
          <a
            style={{
              ...styles.link,
              color: showError ? colors.red : colors.main,
            }}
            target="_blank"
            href="/documente/PrivacyPolicy.pdf"
          >
            <span>prelucrarea datelor cu caracter personal</span>
          </a>
          .
        </span>
      ) : (
        labelText
      )}
    </label>,
  ]
}
