import React from "react"
import { colors } from "../styles/globals"
import svgs from "./Dash/svgs"

export default function Steps({ steps, title, step, goBack }) {
  return (
    <div key={step} className="cereriStepsAndTitleContainer">
      <div className="cereriStepsContainer">
        {step > 0 && step < steps.length && (
          <div className="stepsGoBackIcon" onClick={goBack}>
            {svgs.back}
          </div>
        )}
        {steps.map((item, index) => (
          <span key={index} className="stepContainer">
            <div
              className={
                "stepCircle" +
                (index < steps.length - 1 ? " dotsAfter" : " dotsBefore") +
                (index === step ? " stepCircleActive" : "")
              }
            >
              {index >= step && index + 1 + "/" + steps.length}
              {index < step && svgs.checked}
            </div>
          </span>
        ))}
      </div>
      <div className="cereriTitle">{title}</div>
    </div>
  )
}
