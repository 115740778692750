import { navigate } from "gatsby-link"
import React from "react"
import { getUserInfo } from "../../utils/services/keepAuth"
import { cereriStyles } from "./styles"

export default function ReturnHomeButton({}) {
  return (
    <button
      style={{ float: "none" }}
      onClick={() => {
        getUserInfo() ? navigate("/app/home") : navigate("/")
      }}
      className="cereriButton buttonHover textButtonHover"
    >
      Întoarce-te la pagina principală
    </button>
  )
}
