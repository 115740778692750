import React, { useRef } from "react"
import { colors, generalStyles } from "../styles/globals"
import { cereriStyles } from "./Cereri/styles"
import svgs from "./Dash/svgs"
import FormInput from "./FormInput"

export default function FormFileInput({
  id,
  onChange,
  placeholder,
  value,
  showWithoutTouched = true,
}) {
  const fileInputRef = useRef()
  return (
    <>
      <div style={{ width: "80%", float: "left" }}>
        <input
          type="file"
          id={id}
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={onChange}
          accept="image/*,.pdf"
        />
        <FormInput
          containerStyle={{ ...styles.inputContainer, marginTop: 0 }}
          style={{
            ...styles.input,
            backgroundColor: colors.white,
          }}
          errorStyle={styles.inputError}
          placeholder={placeholder}
          name={id}
          disabled
          value={value}
          showWithoutTouched={showWithoutTouched}
          errorMessage="Document obligatoriu"
          requiredMessage="Document obligatoriu"
          showErrorText={true}
        />
      </div>
      <div
        style={{ width: "20%", float: "left", cursor: "pointer" }}
        onClick={() => fileInputRef.current.click()}
      >
        <span
          style={{
            width: 24,
            height: 24,
            float: "left",
            marginLeft: 50,
            marginTop: 13,
          }}
        >
          {svgs.addFile}
        </span>
      </div>
    </>
  )
}

const styles = {
  inputContainer: {
    ...generalStyles.inputContainer,
    height: 24,
    width: "100%",
    marginTop: 13,
  },
  input: {
    ...generalStyles.input,
    height: 24,
    textAlign: "left",
    paddingLeft: 10,
    width: "100%",
  },
  inputError: {
    ...cereriStyles.inputError,
    marginLeft: 0,
    // width: 220,
  },
}
