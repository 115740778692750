import { apiBase, checkStatus, customPost } from "./utils"

const baseUrl = `${apiBase}/api/cerereAsociereClc`

export function postCerereAsociereClc(data) {
  const loginData = {
    method: "post",
    headers: {
      // azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  }
  return fetch(baseUrl, loginData).then(checkStatus)
}
