import { apiBase, ApiResponse, checkStatus } from "./utils"

const baseUrl = `${apiBase}/api/cerereAvizAmplasament`

interface PostCerereAvizAmplasamentResponse extends ApiResponse<null> {
  filesToLarge: boolean
}

export function postCerereAvizAmplasament(
  data: FormData
): Promise<PostCerereAvizAmplasamentResponse> {
  const loginData = {
    method: "post",
    headers: {
      // azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  }
  return fetch(baseUrl, loginData).then(
    checkStatus
  ) as Promise<PostCerereAvizAmplasamentResponse>
}
