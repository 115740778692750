import React, { useState } from "react"
import { colors, generalStyles } from "../../styles/globals"
import { StaticImage } from "gatsby-plugin-image"
import Spinner from "../Spinner"
import svgs from "../Dash/svgs"
import FormInput from "../FormInput"
import CereriCheckBoxs from "../Cereri/CheckBoxs"
import { cereriStyles } from "../Cereri/styles"
import { ALFA_NUMERIC_REGEX } from "../../utils/regex"

const styles = {
  modalContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2,
  },
  loginLogoContainer: {
    width: 20,
    height: 20,
    top: 16,
    right: 24,
    position: "absolute",
    cursor: "pointer",
    // backgroundColor: "red",
  },
  loginContainer: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    width: 480,
    minHeight: 520,
    marginLeft: -260,
    marginTop: -240,
    backgroundColor: colors.loginModalBackground,
    textAlign: "center",
  },
  loginButton: {
    padding: 8,
    backgroundColor: colors.main,
    color: colors.white,
    fontSize: 20,
    textAlign: "center",
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
  },
}

const checkFormData = formData => {
  if (formData.isCasnic)
    return formData.clc.length > 0 && formData.codClient.length > 0
  else return formData.codClient.length > 0
}

export default function SecondStepAccesConsumuri({
  formData,
  getExcellConsumuri,
  infoMsg,
  addNewClc,
}) {
  const [showInputErrors, setShowInputErrors] = useState(false)
  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleFormKeyPressed = event => {
    if (event.charCode === 13) submitForm()
  }

  return (
    // <div style={styles.modalContainer} onKeyDown={handleFormKeyPressed}>
    <div style={styles.loginContainer}>
      <StaticImage
        style={{ marginTop: 32 }}
        height={120}
        loading="eager"
        layout="constrained"
        src="../images/logoNou.png"
        alt="Logo gazmir"
      />
      <div className="modalForm">
        <div
          style={{
            marginTop: infoMsg ? 16 : 32,
            textAlign: "center",
            marginBottom: 16,
          }}
        >
          <button
            className="buttonHover textButtonHover"
            style={styles.loginButton}
            onClick={getExcellConsumuri}
            disabled={!checkFormData(formData)}
          >
            Download excel consumuri
          </button>
        </div>
        {!formData.isCasnic && (
          <div
            style={{
              marginTop: infoMsg ? 16 : 32,
              textAlign: "center",
              marginBottom: 16,
            }}
          >
            <button
              className="buttonHover textButtonHover"
              style={styles.loginButton}
              onClick={addNewClc}
              disabled={!checkFormData(formData)}
            >
              Asociază clc nou
            </button>
          </div>
        )}
      </div>
      {infoMsg && (
        <>
          <Spinner
            containerStyle={{
              width: 41,
              height: 41,
              margin: "40px auto 0px auto",
            }}
            show={infoMsg === "Vă rugăm așteptați"}
          />
          <div
            style={{
              padding: "30px 60px",
              // marginTop: 32,
              textAlign: infoMsg.length > 44 ? "justify" : "center",
              fontSize: 18,
              color:
                infoMsg === "Vă rugăm așteptați"
                  ? colors.main
                  : infoMsg.includes("succes")
                  ? colors.green
                  : colors.red,
            }}
          >
            {infoMsg}
          </div>
        </>
      )}
    </div>
    // </div>
  )
}
