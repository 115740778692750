import { getToken } from "../services/keepAuth"
import { apiBase, checkStatusForBlob, customGet, customPost } from "./utils"

const baseUrl = `${apiBase}/api/accesConsumuri`

export function postAuthClient(data) {
  return customPost(`${baseUrl}/authClient`, data)
}

export function postAuthFurnizor(data) {
  return customPost(`${baseUrl}/authFurnizor`, data)
}

export function postGenerateExcellConsumuri(data) {
  const loginData = {
    method: "post",
    headers: {
      // azza: getToken(),
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }
  return fetch(`${baseUrl}/generateExcellConsumuri`, loginData).then(
    checkStatusForBlob
  )
}
