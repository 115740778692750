import React from "react"
import { cereriStyles } from "./styles"

export default function CereriCheckBoxs({
  title,
  nameIs,
  labelIs,
  labelIsNot,
  setFormData,
  value,
  horizontal = true,
}) {
  const textWidth = horizontal ? "60%" : "100%"
  const inputWidth = horizontal ? "20%" : "100%"
  return (
    <>
      <span
        className={
          "cereriCheckBoxTitle colorPrimary fontBold textVerticalAllign" +
          (horizontal ? "" : " cereriCheckBoxVerticalTitle")
        }
        // style={{
        //   width: textWidth,
        //   float: "left",
        //   ...cereriStyles.textLabelStyle,
        // }}
      >
        {title}
      </span>
      <span
        className={
          "cereriCheckBoxInputContainer" +
          (horizontal ? "" : " cereriCheckBoxInputContainerVertical")
        }
      >
        <input
          type="checkbox"
          id={nameIs}
          name={nameIs}
          checked={value}
          onChange={() =>
            setFormData(oldFormData => ({
              ...oldFormData,
              [nameIs]: true,
            }))
          }
        />
        <span
          className={
            "showOn500 roundCheckBoxCereri" +
            (value ? " roundCheckBoxCereriChecked" : "")
          }
        />
        <label
          // style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }}
          htmlFor={nameIs}
        >
          {labelIs}
        </label>
      </span>
      <span
        className={
          "cereriCheckBoxInputContainer" +
          (horizontal ? "" : " cereriCheckBoxInputContainerVertical")
        }
      >
        <input
          type="checkbox"
          id={"not" + nameIs}
          name={"not" + nameIs}
          checked={!value}
          onChange={() =>
            setFormData(oldFormData => ({
              ...oldFormData,
              [nameIs]: false,
            }))
          }
        />
        <span
          className={
            "showOn500 roundCheckBoxCereri" +
            (!value ? " roundCheckBoxCereriChecked" : "")
          }
        />
        <label
          // style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }}
          htmlFor={"not" + nameIs}
        >
          {labelIsNot}
        </label>
      </span>
    </>
  )
}
