import React from "react"
import ReturnHomeButton from "./ReturnHomeButton"

type PropTypes = {
  title: string
  steps: string[]
}

export default function InfoSteps({ title, steps }: PropTypes) {
  return (
    <div className="infoStepsCereri">
      <div className="cereriRow">
        <span
          className="infoStepsText colorGreen"
          // style={{
          //   ...cereriStyles.textLabelMultiline,
          //   color: colors.green,
          //   paddingTop: 2,
          // }}
        >
          {title}
        </span>
      </div>
      <div className="stepsContainer">
        {steps.map(item => (
          <div key={item} className="cereriRow">
            <span className="infoStepsText colorPrimary">{item}</span>
          </div>
        ))}
      </div>
      <div className="cereriRow textAlignCenter">
        <ReturnHomeButton />
      </div>
    </div>
  )
}
