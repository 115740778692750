import React, { useRef, useState } from "react"
import PageWithBackgroundImage from "../../components/pageWithBackgroundImage"
import AuthForm from "../../components/AccesConsumuri/authForm"
import SecondStepAccesConsumuri from "../../components/AccesConsumuri/SecondStep"
import {
  postAuthClient,
  postAuthFurnizor,
  postGenerateExcellConsumuri,
} from "../../utils/api/accesConsumuri"
import FileSaver from "file-saver"
import CerereAsociereClc from "../../components/AccesConsumuri/CerereAsociereClc"
import { Helmet } from "react-helmet"
export default function Home({ location }) {
  const [step, setStep] = useState(0)
  const [infoMsg, setInfoMsg] = useState(null)
  const [formData, setFormData] = useState({
    isCasnic: true,
    clc: "",
    codClient: "",
  })

  const [showCerere, setShowCerere] = useState(false)
  // const [addClcData,setAddClcData] = useState({

  // })

  const dateFurnizor = useRef(null)
  const tryAuth = async () => {
    try {
      setInfoMsg("Vă rugăm așteptați")
      let apiResponse
      if (formData.isCasnic) apiResponse = await postAuthClient(formData)
      else apiResponse = await postAuthFurnizor(formData)
      if (apiResponse.type) {
        dateFurnizor.current = apiResponse.data
        setInfoMsg("Autentificare reușită. Veți fi redirecționat.")
        setTimeout(() => {
          setStep(1)
          setInfoMsg(null)
        }, 1000)
      } else setInfoMsg("Credențialele introduse sunt greșite!")
    } catch (error) {
      setInfoMsg("Eroare cerere. Vă rugăm reîncercați")
    }
  }

  const getExcellConsumuri = async () => {
    try {
      const data = await postGenerateExcellConsumuri(formData)
      if (data) FileSaver.saveAs(data, "ExportConsumuri.xlsx")
    } catch (error) {
      console.log(error)
    }
  }

  // const closeForm = () => {
  //   setStep(-1)
  // }

  const showCerereAsociereClc = () => {
    setShowCerere(true)
    setStep(-1)
  }

  const addNewClc = () => {
    setShowCerere(true)
    setStep(-1)
  }

  return (
    <PageWithBackgroundImage location={location}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {step === 0 && (
        <AuthForm
          formData={formData}
          setFormData={setFormData}
          tryAuth={tryAuth}
          infoMsg={infoMsg}
          // closeForm={closeForm}
          showCerereAsociereClc={showCerereAsociereClc}
        />
      )}
      {step === 1 && (
        <SecondStepAccesConsumuri
          formData={formData}
          getExcellConsumuri={getExcellConsumuri}
          infoMsg={infoMsg}
          addNewClc={addNewClc}
        />
      )}
      {showCerere && (
        <CerereAsociereClc datePrecompletate={dateFurnizor.current} />
      )}
    </PageWithBackgroundImage>
  )
}
