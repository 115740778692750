import React, { useState } from "react"
import { colors, generalStyles } from "../../styles/globals"
import { StaticImage } from "gatsby-plugin-image"
import Spinner from "../Spinner"
import svgs from "../Dash/svgs"
import FormInput from "../FormInput"
import CereriCheckBoxs from "../Cereri/CheckBoxs"
import { cereriStyles } from "../Cereri/styles"
import { ALFA_NUMERIC_REGEX } from "../../utils/regex"
import { navigate } from "gatsby"

const styles = {
  modalContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2,
  },
  loginLogoContainer: {
    width: 20,
    height: 20,
    top: 16,
    right: 24,
    position: "absolute",
    cursor: "pointer",
    // backgroundColor: "red",
  },
  loginContainer: {
    position: "absolute",
    top: "50vh",
    left: "50vw",
    width: 480,
    minHeight: 520,
    marginLeft: -260,
    marginTop: -240,
    backgroundColor: colors.loginModalBackground,
    textAlign: "center",
  },
  loginButton: {
    width: 120,
    height: 40,
    backgroundColor: colors.main,
    color: colors.white,
    fontSize: 20,
    textAlign: "center",
    border: "none",
    borderRadius: 4,
    cursor: "pointer",
  },
}

const checkFormData = formData => {
  if (formData.isCasnic)
    return formData.clc.length > 0 && formData.codClient.length > 0
  else return formData.codClient.length > 0
}

export default function AuthForm({
  formData,
  setFormData,
  tryAuth,
  infoMsg,
  showCerereAsociereClc,
}) {
  const [showInputErrors, setShowInputErrors] = useState(false)
  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const submitForm = () => {
    tryAuth(formData)
  }

  const handleFormKeyPressed = event => {
    if (event.charCode === 13) submitForm()
  }

  return (
    // <div style={styles.modalContainer} >
    <div className="modalContainer" onKeyDown={handleFormKeyPressed}>
      <div className="loginContainer">
        <div
          role="button"
          tabIndex={0}
          style={{
            width: 20,
            height: 20,
            top: 16,
            right: 24,
            position: "absolute",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        >
          {svgs.closeIcon}
        </div>
        <StaticImage
          style={{ marginTop: 32 }}
          height={120}
          loading="eager"
          layout="constrained"
          src="../images/logoNou.png"
          alt="Logo gazmir"
        />
        <div className="cereriRow">
          <span
            style={{
              width: "50%",
              float: "left",
              marginLeft: 0,
            }}
          >
            <input
              type="checkbox"
              id="isCasnic"
              name="isCasnic"
              checked={formData.isCasnic}
              onChange={() => {
                setFormData(oldFormData => ({
                  clc: "",
                  codClient: "",
                  isCasnic: true,
                }))
                setShowInputErrors(false)
              }}
            />
            <label
              style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }}
              htmlFor="isCasnic"
            >
              Client final
            </label>
          </span>
          <span
            style={{
              width: "50%",
              float: "left",
              marginLeft: 0,
            }}
          >
            <input
              type="checkbox"
              id="notisCasnic"
              name="notisCasnic"
              checked={!formData.isCasnic}
              onChange={() => {
                setFormData(oldFormData => ({
                  clc: "",
                  codClient: "",
                  isCasnic: false,
                }))
                setShowInputErrors(false)
              }}
            />
            <label
              style={{ paddingLeft: 10, ...cereriStyles.textLabelStyle }}
              htmlFor="notisCasnic"
            >
              Furnizor
            </label>
          </span>
        </div>
        <FormInput
          key={formData.isCasnic ? "CCOD" : "FCOD"}
          containerClassName="marginTop16"
          errorTextClassName="marginTop4"
          // containerStyle={{ ...generalStyles.inputContainer, marginTop: 16 }}
          // style={generalStyles.input}
          // errorStyle={{ ...generalStyles.inputErrorMsg, marginLeft: 50 }}
          type="codClient"
          placeholder="Cod client"
          name="codClient"
          onChange={handleInputChange}
          value={formData.codClient}
          errorTest={ALFA_NUMERIC_REGEX}
          errorMessage="Introduceți codul dvs. de abonat"
          showWithoutTouched={showInputErrors}
        />
        {formData.isCasnic && (
          <FormInput
            containerClassName="marginTop32"
            errorTextClassName="marginTop4"
            type="clc"
            placeholder="Clc-ul locului de consum"
            name="clc"
            onChange={handleInputChange}
            value={formData.clc}
            errorTest={ALFA_NUMERIC_REGEX}
            errorMessage="Introduceți clc-ul locului de consum"
            showWithoutTouched={showInputErrors}
          />
        )}
        {!formData.isCasnic && (
          <div style={{ marginTop: 12 }}>
            <a
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: colors.main,
              }}
              onClick={showCerereAsociereClc}
            >
              Nu ai cont?
            </a>
          </div>
        )}
        {infoMsg !== "Vă rugăm așteptați" && (
          <div
            style={{
              marginTop: infoMsg ? 16 : 32,
              textAlign: "center",
              marginBottom: 16,
            }}
            onMouseEnter={() => setShowInputErrors(true)}
          >
            <button
              className="buttonHover textButtonHover"
              style={styles.loginButton}
              onClick={submitForm}
              disabled={!checkFormData(formData)}
            >
              Conectare
            </button>
          </div>
        )}
        {infoMsg && (
          <>
            <Spinner
              containerStyle={{
                width: 41,
                height: 41,
                margin: "40px auto 0px auto",
              }}
              show={infoMsg === "Vă rugăm așteptați"}
            />
            <div
              style={{
                padding: "30px 60px",
                // marginTop: 32,
                textAlign: "center",
                fontSize: 18,
                color:
                  infoMsg === "Vă rugăm așteptați"
                    ? colors.main
                    : infoMsg.includes("reușită")
                    ? colors.green
                    : colors.red,
              }}
            >
              {infoMsg}
            </div>
          </>
        )}
      </div>
    </div>
    // </div>
  )
}
