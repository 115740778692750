import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-date-picker"
import CereriLayout from "../../components/Cereri/CereriLayout"
import CereriCheckBoxs from "../../components/Cereri/CheckBoxs"
import CustomCheckInput from "../../components/Cereri/CustomCheckInput"
import FilesList from "../../components/Cereri/FilesList"
import InfoSteps from "../../components/Cereri/InfoSteps"
import MultiFileUploader, {
  calcFilesSize,
} from "../../components/Cereri/MultiFileUploader"
import { cereriStyles } from "../../components/Cereri/styles"
import FormFileInput from "../../components/FormFileInput"
import FormInput from "../../components/FormInput"
import PageWithBackgroundImage from "../../components/pageWithBackgroundImage"
import PaymentForm from "../../components/PaymentForm"
import Spinner from "../../components/Spinner"
import Steps from "../../components/Steps"
import TextArea from "../../components/TextArea"
import { colors, generalStyles } from "../../styles/globals"
import {
  dateToDesiredFormat,
  displayNumericValue,
  isUserCasnic,
} from "../../utils"
import { postCerereAsociereClc } from "../../utils/api/cerereAsociereClc"
import { postCerereAvizAmplasament } from "../../utils/api/cerereAvizAmplasament"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  AMOUNT_REGEX,
  CNP_ERROR,
  CNP_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  NUMERIC_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../utils/regex"
import { getContracts, getUserInfo } from "../../utils/services/keepAuth"

const infoStepsClient = [
  `1. Solicitantul completeaza datele necesare depunerii cererii de asociere clc furnizor`,
  `2. Solicitantul încarcă documentatia necesara`,
  `3. Primirea validării documentației depuse (sau, după caz, a completărilor necesare)`,
  `4. Solicitantul va primi raspunsul pentru prezenta cerere`,
]

const requiredFilesName = [
  "Buletin persoană fizică sau Certificatul de Înregistrare al societății",
  "Actele de proprietate",
  "Documente care atestă schimbarea numelui, in cazul în care actele de proprietate sunt pe numele vechi",
  "Acordul scris al clientului prin care permite accesul furnizorului la consumul său",
  "Alte documente",
]

const checkFirstStep = (formData, casnicFormData, nonCasnicFormData) =>
  ALFA_NUMERIC_REGEX(formData.denumireFurnizor) &&
  ALFA_NUMERIC_REGEX(formData.cuiFurnizor) &&
  ALFA_NUMERIC_REGEX(formData.nrcFurnizor) &&
  ALFA_NUMERIC_REGEX(formData.adresaFurnizor) &&
  ALFA_NUMERIC_REGEX(formData.persImputernicitaFurnizor) &&
  CNP_REGEX(formData.cnpPersoanaImputernicitaFurnizor) &&
  ALFA_NUMERIC_REGEX(formData.clc) &&
  EMAIL_REGEX(formData.emailFurnizor) &&
  PHONENUMBER_REGEX(formData.telefonFurnizor) &&
  (formData.isCasnic
    ? ALFA_NUMERIC_REGEX(casnicFormData.nume) &&
      ALFA_NUMERIC_REGEX(casnicFormData.adresa)
    : ALFA_NUMERIC_REGEX(nonCasnicFormData.denumire) &&
      ALFA_NUMERIC_REGEX(nonCasnicFormData.cui) &&
      ALFA_NUMERIC_REGEX(nonCasnicFormData.nrc) &&
      ALFA_NUMERIC_REGEX(nonCasnicFormData.adresa) &&
      ALFA_NUMERIC_REGEX(nonCasnicFormData.persoanaImputernicita) &&
      CNP_REGEX(nonCasnicFormData.cnpPersoanaImputernicita))

const checkSecondStep = (formData, files) =>
  formData.acordDate &&
  formData.acordContactCompletari &&
  formData.acordTransmitere &&
  files.length > 0 &&
  calcFilesSize(files) < 14.99

const minDate = new Date()
minDate.setDate(minDate.getDate() + 21)

const initialFormData = {
  isCasnic: true,
  denumireFurnizor: "",
  cuiFurnizor: "",
  nrcFurnizor: "",
  adresaFurnizor: "",
  persImputernicitaFurnizor: "",
  cnpPersoanaImputernicitaFurnizor: "",
  emailFurnizor: "",
  telefonFurnizor: "",
  clc: "",
  furnizorActual: "",
  dataTransfer: minDate,
  acordDate: false,
  acordContactCompletari: false,
  acordTransmitere: false,
}

const initialCasnicFormData = {
  nume: "",
  adresa: "",
  telefon: "",
  email: "",
}

const initialNonCasnicFormData = {
  denumire: "",
  cui: "",
  nrc: "",
  adresa: "",
  persoanaImputernicita: "",
  cnpPersoanaImputernicita: "",
  telefon: "",
  email: "",
}

const calcTarifAvizAmplasament = lungimeConducta => {
  let deReturnat = 235
  if (lungimeConducta > 100) deReturnat += 0.5 * (100 - lungimeConducta)
  return deReturnat
}

export default function CerereAsociereClc({ datePrecompletate }) {
  const [infoMsg, setInfoMsg] = useState(null)
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState(initialFormData)

  const [casnicFormData, setCasnicFormData] = useState(initialCasnicFormData)
  const [nonCasnicFormData, setNonCasnicFormData] = useState(
    initialNonCasnicFormData
  )

  const formRef = useRef()
  const jsonRequestRef = useRef()
  const checksumRef = useRef()

  const [files, setFiles] = useState([])
  // console.log(files)

  const [nrHover, setNrHover] = useState(0)

  useEffect(() => {
    if (datePrecompletate)
      setFormData(oldFormData => ({
        ...oldFormData,
        ...datePrecompletate,
      }))
  }, [datePrecompletate])

  const handleFormDataChange = (value, name) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleCasnicFormDataChange = (value, name) => {
    setCasnicFormData(oldData => ({
      ...oldData,
      [name]: value,
    }))
  }

  const handleNonCasnicFormDataChange = (value, name) => {
    setNonCasnicFormData(oldData => ({
      ...oldData,
      [name]: value,
    }))
  }

  const changeDataSchimbare = newDate => {
    setFormData(oldFormData => ({
      ...oldFormData,
      dataSchimbare: newDate,
    }))
  }

  const changeFile = event => {
    const { files, id } = event.target
    // console.log(id, typeof id)
    setFiles(oldFiles => ({
      ...oldFiles,
      [id]: files[0],
    }))
  }

  const changeDataTransfer = newDate => {
    setFormData(oldFormData => ({
      ...oldFormData,
      dataTransfer: newDate,
    }))
  }

  const sendForm = async () => {
    const data = new FormData()

    for (var i in files) data.append("file", files[i])

    data.append("denumireFurnizor", formData.denumireFurnizor)
    data.append("cuiFurnizor", formData.cuiFurnizor)
    data.append("nrcFurnizor", formData.nrcFurnizor)
    data.append("adresaFurnizor", formData.adresaFurnizor)
    data.append("persImputernicitaFurnizor", formData.persImputernicitaFurnizor)
    data.append(
      "cnpPersoanaImputernicitaFurnizor",
      formData.cnpPersoanaImputernicitaFurnizor
    )
    data.append("emailFurnizor", formData.emailFurnizor)
    data.append("telefonFurnizor", formData.telefonFurnizor)
    data.append("clc", formData.clc)
    data.append("furnizorActual", formData.furnizorActual)
    data.append("isCasnic", formData.isCasnic)
    if (formData.isCasnic) {
      data.append("nume", casnicFormData.nume)
      data.append("adresa", casnicFormData.adresa)
      data.append("email", casnicFormData.email)
      data.append("telefon", casnicFormData.telefon)
    } else {
      data.append("denumire", nonCasnicFormData.denumire)
      data.append("cui", nonCasnicFormData.cui)
      data.append("nrc", nonCasnicFormData.nrc)
      data.append("adresa", nonCasnicFormData.adresa)
      data.append(
        "persoanaImputernicita",
        nonCasnicFormData.persoanaImputernicita
      )
      data.append(
        "cnpPersoanaImputernicita",
        nonCasnicFormData.cnpPersoanaImputernicita
      )
      data.append("telefon", nonCasnicFormData.telefon)
      data.append("email", nonCasnicFormData.email)
    }
    data.append("dataTransfer", formData.dataTransfer)
    data.append("acordDate", formData.acordDate)
    data.append("acordContactCompletari", formData.acordContactCompletari)
    data.append("acordTransmitere", formData.acordTransmitere)

    try {
      setInfoMsg("Vă rugăm așteptați")
      const apiResponse = await postCerereAsociereClc(data)
      if (apiResponse.type) {
        setStep(2)
      } else if (apiResponse.filesToLarge)
        setInfoMsg(
          "A apărut o eroare la transmiterea cererii. Fișierele încărcate depășesc limita de 20MB. Scoateți fișierele care au o dimensiunea mare. Vă vom solicita restul documentelor prin email. Vă mulțumim!"
        )
      else
        setInfoMsg(
          "A apărut o eroare la transmiterea cererii. Vă rugăm reîncercați"
        )
    } catch (error) {
      console.log(error)
      setInfoMsg(
        "A apărut o eroare la transmiterea cererii. Vă rugăm reîncercați"
      )
    }
  }

  const goBack = () => {
    setStep(oldValue => oldValue - 1)
  }

  return (
    <div
      id="rootAudienta"
      className="cereriContainer"
      style={{
        ...cereriStyles.root,
        top: 110,
        maxHeight: "calc(100vh - 100px)",
      }}
    >
      <Steps
        steps={[1, 2]}
        title="Cerere asociere clc furnizor"
        step={step}
        goBack={goBack}
      />
      <div
        style={{
          display: "block",
          maxHeight: "calc(100vh - 260px)",
          overflowY: "auto",
        }}
      >
        {step === 0 && (
          <div>
            <div
              style={{ ...cereriStyles.row, ...cereriStyles.textLabelStyle }}
            >
              {"Date de identificare societate (furnizor):"}
            </div>
            <div style={cereriStyles.row}>
              <FormInput
                containerStyle={cereriStyles.inputContainer}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder={"Denumire"}
                name="denumireFurnizor"
                onChange={handleFormDataChange}
                value={formData.denumireFurnizor}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div style={cereriStyles.row}>
              <FormInput
                containerStyle={{
                  ...cereriStyles.inputContainer,
                  width: "50%",
                  paddingRight: 24,
                }}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder={"CUI"}
                name="cuiFurnizor"
                onChange={handleFormDataChange}
                value={formData.cuiFurnizor}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                containerStyle={{
                  ...cereriStyles.inputContainer,
                  width: "50%",
                  paddingLeft: 24,
                }}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder="Număr înregistrare ORC"
                name="nrcFurnizor"
                onChange={handleFormDataChange}
                value={formData.nrcFurnizor}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div style={cereriStyles.row}>
              <FormInput
                containerStyle={{
                  ...cereriStyles.inputContainer,
                  width: "100%",
                }}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder="Adresă sediu social"
                name="adresaFurnizor"
                onChange={handleFormDataChange}
                value={formData.adresaFurnizor}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div style={cereriStyles.row}>
              <FormInput
                containerStyle={{
                  ...cereriStyles.inputContainer,
                  width: "50%",
                  paddingRight: 24,
                }}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder="Persoană împuternicită"
                name="persImputernicitaFurnizor"
                onChange={handleFormDataChange}
                value={formData.persImputernicitaFurnizor}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                containerStyle={{
                  ...cereriStyles.inputContainer,
                  width: "50%",
                  paddingLeft: 24,
                }}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder="CNP persoană împuternicită"
                name="cnpPersoanaImputernicitaFurnizor"
                onChange={handleFormDataChange}
                value={formData.cnpPersoanaImputernicitaFurnizor}
                errorTest={CNP_REGEX}
                errorMessage={CNP_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div style={cereriStyles.row}>
              <FormInput
                containerStyle={{
                  ...cereriStyles.inputContainer,
                  width: "50%",
                  paddingRight: 24,
                }}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder="Email"
                name="emailFurnizor"
                onChange={handleFormDataChange}
                value={formData.emailFurnizor}
                errorTest={EMAIL_REGEX}
                errorMessage={EMAIL_ERROR}
                showWithoutTouched={nrHover > 1}
              />
              <FormInput
                containerStyle={{
                  ...cereriStyles.inputContainer,
                  width: "50%",
                  paddingLeft: 24,
                }}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder="Telefon"
                name="telefonFurnizor"
                onChange={handleFormDataChange}
                value={formData.telefonFurnizor}
                errorTest={PHONENUMBER_REGEX}
                errorMessage={PHONENUMBER_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div style={cereriStyles.row}>
              <FormInput
                containerStyle={cereriStyles.inputContainer}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder={`CLC client`}
                name="clc"
                onChange={handleFormDataChange}
                value={formData.clc}
                errorTest={ALFA_NUMERIC_REGEX}
                errorMessage={ALFA_NUMERIC_ERROR}
                showWithoutTouched={nrHover > 1}
              />
            </div>
            <div style={cereriStyles.row}>
              <FormInput
                containerStyle={cereriStyles.inputContainer}
                style={cereriStyles.input}
                errorStyle={cereriStyles.inputError}
                placeholder="Furnizor actual (opțional)"
                name="furnizorActual"
                onChange={handleFormDataChange}
                value={formData.furnizorActual}
                isRequired={false}
              />
            </div>
            <div style={cereriStyles.row}>
              <CereriCheckBoxs
                title="Tip client"
                nameIs="isCasnic"
                labelIs="Casnic"
                labelIsNot="Comercial"
                setFormData={setFormData}
                value={formData.isCasnic}
              />
            </div>

            <div
              style={{ ...cereriStyles.row, ...cereriStyles.textLabelStyle }}
            >
              {formData.isCasnic
                ? "Datele personale (din C.I.):"
                : "Date de identificare societate:"}
            </div>
            {formData.isCasnic ? (
              <>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={cereriStyles.inputContainer}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Nume și prenume"
                    name="nume"
                    onChange={handleCasnicFormDataChange}
                    value={casnicFormData.nume}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={cereriStyles.inputContainer}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Adresă"
                    name="adresa"
                    onChange={handleCasnicFormDataChange}
                    value={casnicFormData.adresa}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingRight: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Email client"
                    name="email"
                    onChange={handleCasnicFormDataChange}
                    value={casnicFormData.email}
                    isRequired={false}
                  />
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingLeft: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Telefon client"
                    name="telefon"
                    onChange={handleCasnicFormDataChange}
                    value={casnicFormData.telefon}
                    isRequired={false}
                  />
                </div>
              </>
            ) : (
              <>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={cereriStyles.inputContainer}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder={"Denumire"}
                    name="denumire"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.denumire}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingRight: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder={"CUI"}
                    name="cui"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.cui}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingLeft: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Număr înregistrare ORC"
                    name="nrc"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.nrc}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={cereriStyles.inputContainer}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Adresă sediu social"
                    name="adresa"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.adresa}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingRight: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Persoană împuternicită"
                    name="persoanaImputernicita"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.persoanaImputernicita}
                    errorTest={ALFA_NUMERIC_REGEX}
                    errorMessage={ALFA_NUMERIC_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingLeft: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="CNP persoană împuternicită"
                    name="cnpPersoanaImputernicita"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.cnpPersoanaImputernicita}
                    errorTest={CNP_REGEX}
                    errorMessage={CNP_ERROR}
                    showWithoutTouched={nrHover > 1}
                  />
                </div>
                <div style={cereriStyles.row}>
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingRight: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Email client"
                    name="email"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.email}
                    isRequired={false}
                  />
                  <FormInput
                    containerStyle={{
                      ...cereriStyles.inputContainer,
                      width: "50%",
                      paddingLeft: 24,
                    }}
                    style={cereriStyles.input}
                    errorStyle={cereriStyles.inputError}
                    placeholder="Telefon client"
                    name="telefon"
                    onChange={handleNonCasnicFormDataChange}
                    value={nonCasnicFormData.telefon}
                    isRequired={false}
                  />
                </div>
              </>
            )}

            <div style={cereriStyles.row}>
              <div
                style={{
                  width: "67%",
                  float: "left",
                  ...cereriStyles.textLabelStyle,
                  lineHeight: "50px",
                }}
              >
                Data dorită de transfer
              </div>
              <div
                style={{
                  width: "33%",
                  float: "right",
                  paddingTop: 12,
                  lineHeight: "24px",
                  paddingLeft: "24px",
                }}
              >
                <DatePicker
                  className="cereriDatePicker floatRight"
                  onChange={changeDataTransfer}
                  value={formData.dataTransfer}
                  minDate={minDate}
                  clearIcon={null}
                />
              </div>
            </div>
            {/* <div style={cereriStyles.row}>
              <CustomCheckInput
                id="acordPublicareDate"
                checked={formData.acordPublicareDate}
                onChange={() =>
                  setFormData(oldFormData => ({
                    ...oldFormData,
                    acordPublicareDate: !oldFormData.acordPublicareDate,
                  }))
                }
                showError={nrHover > 1 && !formData.acordPublicareDate}
                labelText="Sunt de acord cu publicarea datelor pe site-ul GAZMIR"
              />
            </div> */}
            <div
              style={cereriStyles.row}
              onMouseEnter={() => setNrHover(oldValue => oldValue + 1)}
            >
              <div
                style={{
                  float: "left",
                  width: "50%",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: colors.red,
                  lineHeight: "50px",
                }}
              >
                {checkFirstStep(formData, casnicFormData, nonCasnicFormData) ||
                nrHover < 2
                  ? ""
                  : "Una din informațiile obligatorii nu este validă!"}
              </div>
              <button
                disabled={
                  !checkFirstStep(formData, casnicFormData, nonCasnicFormData)
                }
                style={{
                  ...cereriStyles.button,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStep(1)
                  setNrHover(0)
                }}
              >
                Pasul următor
              </button>
            </div>
          </div>
        )}
        {step === 1 && (
          <div>
            <div className="cereriRow">
              <div className="cereriTextLabel">Documente necesare:</div>
            </div>
            <div style={cereriStyles.row}>
              <FilesList items={requiredFilesName} />
            </div>
            <div className="cereriRow">
              <div
                className="cereriTextLabel"
                style={{
                  cursor: "initial",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <span style={{ float: "left", minWidth: 200 }}>
                  Documente anexate:
                </span>
                <span
                  className="hideOn600"
                  style={{
                    // float: "right",
                    color:
                      calcFilesSize(files) > 14.99 ? colors.red : colors.main,
                  }}
                >{`Dimensiune: ${calcFilesSize(files).toLocaleString("ro-RO", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} MB`}</span>
              </div>
            </div>
            {/* {files.length > 0 && <FilesList items={files} labelKey="name" />} */}

            <div
              className="cereriRow"
              style={{
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              <MultiFileUploader filesChanged={setFiles} />
            </div>
            <div style={cereriStyles.row}>
              <CustomCheckInput
                id="acordDate"
                checked={formData.acordDate}
                onChange={() =>
                  setFormData(oldFormData => ({
                    ...oldFormData,
                    acordDate: !oldFormData.acordDate,
                  }))
                }
                showError={nrHover > 1 && !formData.acordDate}
                labelText="Sunt de acord cu prelucrarea datelor cu caracter personal de
                  către gazmir.ro"
                isPolicyCheck={true}
              />
            </div>
            <div style={cereriStyles.row}>
              <CustomCheckInput
                id="acordContactCompletari"
                checked={formData.acordContactCompletari}
                onChange={() =>
                  setFormData(oldFormData => ({
                    ...oldFormData,
                    acordContactCompletari: !oldFormData.acordContactCompletari,
                  }))
                }
                showError={nrHover > 1 && !formData.acordContactCompletari}
                labelText="Sunt de acord să fiu contactat în vederea completării/corectării documentației anexe"
              />
            </div>
            <div style={cereriStyles.row}>
              <CustomCheckInput
                id="acordTransmitere"
                checked={formData.acordTransmitere}
                onChange={() =>
                  setFormData(oldFormData => ({
                    ...oldFormData,
                    acordTransmitere: !oldFormData.acordTransmitere,
                  }))
                }
                showError={nrHover > 1 && !formData.acordTransmitere}
                labelText="Doresc să transmit cererea pentru asocierea clientului la contul meu de furnizor"
              />
            </div>
            {infoMsg && (
              <div style={cereriStyles.row}>
                <Spinner
                  containerStyle={{
                    width: 41,
                    height: 41,
                    margin: "10px auto 0px auto",
                  }}
                  show={infoMsg === "Vă rugăm așteptați"}
                />
                <div
                  style={{
                    ...cereriStyles.infoMsg,
                    color: infoMsg.includes("eroare")
                      ? colors.red
                      : colors.main,
                  }}
                >
                  {infoMsg}
                </div>
              </div>
            )}
            <div
              style={cereriStyles.row}
              onMouseEnter={() => setNrHover(oldValue => oldValue + 1)}
            >
              <div
                style={{
                  float: "left",
                  width: "50%",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: colors.red,
                  lineHeight: "50px",
                }}
              >
                {checkSecondStep(formData, files) || nrHover < 2
                  ? ""
                  : files.length === 0
                  ? "Încarcă documentele necesare"
                  : calcFilesSize(files) > 14.99
                  ? "Dimensiunea fișierelor depășeste 15MB"
                  : "Una din informațiile obligatorii nu este validă!"}
              </div>
              <button
                disabled={!checkSecondStep(formData, files)}
                style={{
                  ...cereriStyles.button,
                  cursor: "pointer",
                }}
                onClick={sendForm}
              >
                Transmite cererea
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <InfoSteps
            title="Cererea a fost transmisă cu succes. Etapele procesului de
          asociere clc (schimbare titular) :"
            steps={infoStepsClient}
          />
        )}
      </div>
    </div>
  )
}
